<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Request From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.requestFrom.val" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Request To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.requestTo.val" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Complete From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.completeFrom.val" />
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Complete To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.completeTo.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Submitted By</label>
                <Typeahead cdi_type="employee" v-bind:ID.sync="state.optionFields.submittedById.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Drafter</label>
                <Typeahead cdi_type="employee" v-bind:ID.sync="state.optionFields.drafterId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Company</label>
                <Typeahead cdi_type="company" v-bind:ID.sync="state.optionFields.companyId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Drawing Type</label>
                <select class="form-control" v-model='state.optionFields.drawingType.val'>
                    <option value="0">All</option>
                    <option value="1">Existing 2D</option>
                    <option value="2">New 2D</option>
                    <option value="3">Curb 3D</option>
                    <option value="4">Installation</option>
                    <option value="5">Customer Drawing</option>
                    <option value="6">Engineering</option>
                    <option value="7">Flow</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Request Type</label>
                <select class="form-control" v-model='state.optionFields.requestType.val'>
                    <option value="all">All</option>
                    <option value="closed">Finished</option>
                    <option value="open">Not Finished</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Quote</label><input type=text class='form-control' v-model='state.optionFields.quote.val' >
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Order</label><input type=text class='form-control' v-model='state.optionFields.tracker.val' >
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Customer</label>
                <Typeahead ref="customer" cdi_type="user" v-bind:ID.sync="state.optionFields.distributor.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox :true-value="1" :false-value=null v-model='state.optionFields.missingInfo.val'> Missing Info Only</label>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-show="!toggleHide">
                <label><input type=checkbox :true-value="1" :false-value=null v-model='state.optionFields.showTotals.val'> Show Totals</label>
            </div>

            <div class='col-xs-12 ' v-show="!toggleHide" ><div class="filter_separator" >Item Type <small>(All if none are checked)</small></div></div>

            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide"   v-for="[curbTypeHistoricalId, curbTypeHistorical] in cache.curbTypeCache.CURB_TYPES_HISTORICAL" v-bind:key="curbTypeHistoricalId + curbTypeHistorical" >
                <label ><input type=checkbox :true-value="1" v-model="state.optionFields.itemType.val" :false-value=null :value="curbTypeHistoricalId"> {{ curbTypeHistorical }}</label>
            </div>

            <div class='col-xs-12' v-show="!toggleHide"  >
                <div class="filter_separator" v-show="!toggleHide">Level Select <small>(All if none are checked)</small>
                </div>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid' v-show="!toggleHide"  v-for="[customerLevelId, customerLevel] in cache.customerLevelCache.CUSTOMER_LEVELS" v-bind:key="customerLevelId + customerLevel">
                <label v-show="!toggleHide"><input type=checkbox :true-value="1"  v-model="state.optionFields.levelSelect.val" :false-value=null :value="customerLevelId"> {{ customerLevel }}</label>
            </div>

            <div class="col-xs-12">
            <button type="button" class="btn btn-default btn-sm" @click="toggleMoreFilters">
                {{ (toggleHide) ? 'More' : 'Less' }} Filters
            </button>
        </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import customerLevelCache from '@/cache/customerLevel.cache.js';
    import curbTypeCache from  '@/cache/curbType.cache.js'
    import Typeahead from '@/components/utils/typeaheads/Typeahead';
    import DatePicker from "../utils/DatePicker";

    export default {
        name: "Options",
        components: {Typeahead, DatePicker},
        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    customerLevelCache,
                    curbTypeCache
                }
            }
        },
        methods: {
            toggleMoreFilters: function() {
                this.toggleHide = !this.toggleHide;
                if(this.toggleHide){
                    this.$refs.customer.object = '';
                }
            }
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }
    }
</script>

<style scoped>

</style>