<template>
    <div>
        <h1>Drawing Requests</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from '@/store/Report.store.js';
    import Options from "./drawing_requests_opt.vue";
    import GroupBy from "./utils/GroupBy";
    import Fields from "./utils/Fields";
    import Results from "./utils/Results";
    export default {
        name: 'drawing_requests',
        components: {
            Options,
            GroupBy,
            Results,
            Fields,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    requestFrom: {val: new Date(new Date().setDate(1)).toLocaleDateString(), qsField: '[datetimes][drawing_request_date_from]'},
                    requestTo: {val: new Date().toLocaleDateString(), qsField: '[datetimes][drawing_request_date_to]'},
                    completeFrom: {val: '', qsField: '[datetimes][drawing_request_complete_from]'},
                    completeTo: {val: '', qsField: '[datetimes][drawing_request_complete_to]'},
                    submittedByName: {val: ''},
                    submittedById: {val: '0', qsField: '[numbers][dr.submit_id]'},
                    drafterName: {val: ''},
                    drafterId: {val: '0', qsField: '[drafter_id]'},
                    companyName: {val: ''},
                    companyId: {val: '0', qsField: '[numbers][dr.company_id]'},
                    distributor: {val: '', qsField: '[numbers][distributor.user_id]', defaultVal: ''},
                    drawingType: {val: '0', qsField: '[numbers][drawing_type_id]'},
                    requestType: {val: 'all', qsField: '[show_type]', defaultVal: 'all'},
                    quote: {val: '', qsField: '[quote_id]', defaultVal: ''},
                    tracker: {val: '', qsField: '[tracker_id]', defaultVal: ''},
                    missingInfo: {val: '', qsField: '[isset][dr.missing_info]', defaultVal: ''},
                    showTotals: {val: '1', qsField: '[show_totals]', defaultVal: '1'},
                    itemType: {val: [], qsField: '[in][c.curb_type_id]', defaultVal: []},
                    levelSelect: {val: [], qsField: '[in][com.price_level_id]', defaultVal: []},
                },
                groupByFields: {
                    nomenclature: {label: 'Item'},
                    curb_type: {label: 'Item Type'},
                    quote_id: {label: 'Quote'},
                    tracker_id: {label: 'Order'},
                    submited_by: {label: 'Submitter', checked: true},
                    price_level: {label: 'Level'},
                    company_name: {label: 'Company'},
                    on_time: {label: 'On Time'}
                },
                dataFields: {
                    nomenclature: {label: 'Item', checked: true},
                    curb_type: {label: 'Item Type'},
                    quote_id: {label: 'Quote'},
                    tracker_id: {label: 'Order'},
                    company_name: {label: 'Company', checked: true},
                    price_level: {label: 'Level'},
                    submited_by: {label: 'Submitted', checked: true},
                    distributor: {label: 'Customer', checked: false },
                    drawing_type_id: {label: 'Drawing Type'},
                    completed_time: {label: 'Completion Hrs', checked: true},
                    on_time: {label: 'On Time'},
                    drafter: {label: 'Drafter(s)', checked: true},
                    draft_secs: {label: 'Drawing Mins', checked: true},
                    missing_info: {label: 'Missing Info'}
                }
            }
        }, methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Drawing Requests']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>